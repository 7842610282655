import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container, media } from '../ui';
import Layout from '../components/layout';
import Hero from '../components/Hero';
import { get, trimStart, trimEnd } from 'lodash';
import { graphql } from 'gatsby';
import YouTube from 'react-youtube';
import mediumZoom from 'medium-zoom';
import Seo from '../components/Seo';

const Kicker = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Content = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 1.25rem;
  padding-top: ${({ theme }) => theme.spacing()};

  padding-bottom: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};

  `} ${media.desktop`
    padding-top: ${({ theme }) => theme.spacing(3.75)};
    padding-left: ${({ theme }) => theme.spacing(3.75)};
    padding-right: ${({ theme }) => theme.spacing(3.75)};
    padding-bottom: ${({ theme }) => theme.spacing(3.75)};
  `};
`;

const ArticleBody = styled.div`
  p {
    line-height: 1.5;
  }

  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  img {
    margin: ${({ theme }) => theme.spacing(2)} auto;
    max-height: 600px;
    max-width: 100%;
    border-radius: 4px;
  }

  a {
    color: ${({ theme }) => theme.primary};
  }

  strong {
    display: block;
    line-height: 1.5;
  }

  blockquote {
    box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.24);
    margin: ${({ theme }) => theme.spacing(2)} 0;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(2)};

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`;

const YouTubeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  > * {
    width: 100%;
  }
`;

const Skill = ({ data }) => {
  const skill = get(data, 'datoCmsSkill');

  const getIdFromLink = url => trimEnd(trimStart(url)).split('?v=')[1];

  const options = {
    width: '100%',
    height: 360,
  };

  useEffect(() => {
    mediumZoom(document.querySelectorAll('#body img'));
  }, []);

  return (
    <Layout variant="withAvocadoPattern">
      <Seo
        title={get(skill, 'seo.title') || skill.title}
        description={get(skill, 'seo.description') || skill.kicker}
        image={get(skill, 'seo.image.url')}
        twitterCard={get(skill, 'seo.twitterCard')}
      />
      <Hero type="personnalised" heroSubtitle={skill.title} />
      <Container
        variant="main"
        background="white"
        paddingSize="none"
        size="tight"
      >
        <Content>
          <Kicker>{skill.kicker}</Kicker>
          <ArticleBody
            id="body"
            dangerouslySetInnerHTML={{
              __html: skill.contentNode.childMarkdownRemark.html,
            }}
          ></ArticleBody>
        </Content>
        {get(skill, 'youtube', []).map(({ youtubeLink }, index) => (
          <YouTubeWrapper key={index}>
            <YouTube videoId={getIdFromLink(youtubeLink)} opts={options} />
          </YouTubeWrapper>
        ))}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    datoCmsSkill(slug: { eq: $slug }) {
      title
      slug
      kicker
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      youtube {
        title
        youtubeLink
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;

export default Skill;
