import React from 'react';
import styled from 'styled-components';
import { media } from '../ui';
import avocado from '../images/avocado.svg';

const BackgroundHero = styled.div`
  position: relative;
  color: #fff;
  padding-top: 144px;
  background: ${({ theme }) => theme.primaryGradient};
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(3)};

  ${media.tablet`
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    min-height: 470px;
  `}
`;

const HeroContainer = styled.div`
  max-width: 1000px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: center;

  ${media.desktop`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  `}
`;

const HeroTitle = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.secondary};
  text-transform: uppercase;
`;

const HeroSubTitle = styled.h2`
  font-size: min(calc(1.5rem + ((100vw - 20em) / (50 - 20))), 2.625rem);
  font-weight: 600;
  white-space: wrap;
  margin-top: 0;

  ${media.tablet`
  font-size: 2.625rem
  `}
`;

const HalfAvocadoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
  img {
    height: 300px;
    transform: scaleX(-1);
  }
`;

const Hero = ({ type, heroTitle, heroSubtitle }) => {
  const heroContent = () => {
    switch (type) {
      case 'blog':
        return {
          title: 'mon blog',
          subtitle: 'Retrouver mes articles traitant du droit',
        };
      case 'skills':
        return {
          title: null,
          subtitle: 'Mes compétences',
        };

      case 'personnalised':
        return {
          title: heroTitle,
          subtitle: heroSubtitle,
        };
      default:
        return null;
    }
  };

  const { title, subtitle } = heroContent(type);

  return (
    <BackgroundHero>
      <HeroContainer>
        {title && <HeroTitle>{title}</HeroTitle>}
        <HeroSubTitle>{subtitle}</HeroSubTitle>
      </HeroContainer>
      <HalfAvocadoWrapper>
        <img src={avocado} alt="Avocado logo"></img>
      </HalfAvocadoWrapper>
    </BackgroundHero>
  );
};

export default Hero;
